<template>
  <div class="container">
    <div class="d-flex justify-content-between mb-3 align-items-center">
      <h1 class="m-0 mb-2">Clinics</h1>
      <router-link
        v-if="$can({ key: 'clinics', expectedPermission: 'create' })"
        to="/admin/clinics/create?src=clinics"
        class="btn btn-outline-blue py-2"
      >
        <icon type="plus" /> Add New Clinic
      </router-link>
    </div>

    <alert v-if="isLoading" class="light-shadow" />
    <alert
      v-if="!isLoading && clinics.length == 0"
      :hideLoader="true"
      class="light-shadow"
      >No clinics added yet.</alert
    >
    <div
      class="card theme-card border-lg-0 table-container p-0"
      v-if="!isLoading && clinics.length > 0"
    >
      <table class="table table-striped mb-0">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th
              v-if="$can({ key: 'clinics', expectedPermission: 'edit' })"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="clinic in clinics" :key="clinic.id">
            <td>{{ clinic.id }}</td>
            <td>
              {{ clinic.name }}
            </td>

            <td
              class="text-right"
              v-if="$can({ key: 'clinics', expectedPermission: 'edit' })"
            >
              <router-link :to="`/admin/clinics/${clinic.id}?src=clinics`">
                <button class="btn btn-sm btn-black">Edit</button>
              </router-link>
              <a
                v-if="user && (user.isAdmin || user.isManagingAdmin)"
                href="/"
                class="actionsSeperator btn btn-danger btn-sm ml-3"
                @click="deleteSelectedClinic($event, clinic.id)"
                ><icon type="trash-alt" v-if="!clinic.deleting" />
                <i
                  class="fas fa-spin fa-circle-notch"
                  v-if="clinic.deleting"
                ></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "Clinics",
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState({
      clinics: (state) => state.clinics.data,
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    this.getAllClinics().then(() => {
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions({
      getAllClinics: "clinics/get",
      deleteClinic: "clinics/delete",
    }),

    deleteSelectedClinic: function ($event, id) {
      $event.preventDefault();
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "Deleting the clinic will also delete it from the clients and providers that has been assigned to it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            vm.deleteClinic(id).then((deleted) => {
              if (deleted) {
                this.getAllClinics();
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Clinic has been deleted.",
                  "success"
                );
              } else {
                swalWithBootstrapButtons.fire(
                  "Error!",
                  "Something went wrong...",
                  "error"
                );
              }
            });
          }
        });
    },
  },
};
</script>
